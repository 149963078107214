body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nv {
  margin-top: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* #fluent-default-layer-host {
  z-index: 100000 !important;
} */

/* .ms-Layer {
  z-index: 99999 !important;
} */

pagetitle {
  color: #323130;
  font-size: 28px;
  font-weight: 300;
}

ms-Dialog-main {
  border-radius: 14px !important;
}

.ms-ProgressIndicator .ms-ProgressIndicator-itemProgress {
  padding: 0px !important;
}

.ag-paging-row-summary-panel-number {
  color: #2e2e9c;
  font-weight: 600;
}

.ag-icon-previous {
  color: #001433;
  font-weight: 600;
}

.ag-icon-first {
  color: #001433;
  font-weight: 600;
}

.ag-theme-balham .ag-ltr .ag-cell {
  border-right: solid #d9dcde !important;
  border-right-width: 1px !important;
}

.ag-header-group-cell-label,
.ag-header-cell-label {
  color: #000000 !important;
  font-weight: 500 !important;
  font-size: 11px !important;
}

.ag-theme-balham .ag-header {
  background-color: #fff !important;
}

.ag-theme-balham .ag-checkbox-input-wrapper::after {
  content: "\f126" !important;
}

.ag-theme-balham .ag-checkbox-input-wrapper.ag-checked::after {
  content: "\f127" !important;
}

.ag-theme-balham .ag-checkbox-input-wrapper {
  border-radius: 8px !important;
}

.table-p-0.table>thead>tr>th {
  padding: 2px !important;
  border: none !important;
}

.table-p-0.table>tbody>tr>td {
  padding: 2px !important;
  border: none !important;
}

.table-p-0.table>:not(:first-child) {
  border-top: 1px #efefef solid;
}

.table-p-0.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0px;
  margin-top: 0px !important;
}

.currencyInput {
  height: 32px !important;
  border: 1px solid #000000 !important;
}

.cea-integracaoBlueMessageBar {
  background: #d1edff !important;
}

.cea-headerRoute {
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  color: #e7e7e7 !important;
}

.cea-headerRoute:hover {
  font-size: 14px;
  text-decoration: underline;
  color: #ffffff !important;
}

.cea-contentRegion {
  background-color: #F3F2F1;
  max-height: calc(100vh - 40px);
  overflow-y: hidden;
  height: calc(100vh - 40px);

}

.cea-contentRegion .root-135 {
  max-height: calc(100vh - 40px);
  overflow-y: hidden;
  height: calc(100vh - 40px);
}

.cea-dashedred {
  text-decoration: underline dotted red;
}

.cea-hidden {
  display: none !important;
}

.o365header {
  width: 100% !important;

  height: 40px;
  min-height: 29px;
  flex: 0 0 auto;
  z-index: initial;
  position: relative;
  border-left: 1px solid #444444d1;
}

.cea-mainFullHeader {
  width: 100vw !important;
}

.cea-mainMenuHeader {
  width: calc(100vw - 112px) !important;
  right: -112px !important;
}

.cea-mainSidebar {
  max-height: calc(100vh -40px);
  height: calc(100vh -40px);
  border-right: 1px solid rgb(190, 187, 184);
}

.cea-usermenu-panel {}

.cea-usermenu-panel .ms-Overlay {
  background-color: rgb(0 0 0 / 40%) !important;
}


.cea-menu-panel {}

.cea-menu-panel .ms-Overlay {
  background-color: #00000030 !important;
}

.cea-loading {}

.cea-loading .ms-Overlay--dark {
  background-color: rgb(0 0 0 / 77%) !important;
}

.cea-loading .ms-Dialog-main {
  box-shadow: none !important;
  background-color: transparent !important;
}

.cea-loading .ms-Dialog-header {
  display: none !important;
}

.cea-loading .ms-Dialog-title {
  max-height: 0p !important;
  padding: 0px 0px 0px 0px !important;
}

.cea-loading .ms-ProgressIndicator-itemName {
  color: #ffffff !important;
}

.cea-loading .ms-ProgressIndicator-itemDescription {
  color: #ffffff !important;
}

.cea-loading-text {
  color: #ffffff !important;
  font-size: 15px !important;
  font-weight: 600;
}

.cea-componentContent_FullWidth {
  overflow-y: auto;
  padding-top: 0px;
  height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
}

.cea-filterPanel_close {
  overflow-y: hidden;
  height: 1px !important;
  width: 1px !important;
  background-color: transparent;
  border-right: none;
}

.cea-filterPanel_open {
  overflow-y: auto;
  height: calc(100vh - 40px);
  background-color: #FAF9F8;
  border-right: 1px #c8c8c8 solid;
}

.cea-filterPanel_minimized {
  height: calc(100vh - 40px) !important;
  background-color: #FAF9F8;
  border-right: 1px #c8c8c8 solid;
  width: 35px !important;
  padding: 0px !important;
}

/* prepare wrapper element */
.cea-input div {
  display: inline-block;
  position: relative;
}

/* position the unit to the right of the wrapper */
.cea-input div::after {
  position: absolute;
  top: 2px;
  right: .5em;
  transition: all .05s ease-in-out;
}

/* move unit more to the left on hover or focus within
   for arrow buttons will appear to the right of number inputs */
.cea-input div:hover::after,
.cea-input div:focus-within::after {
  right: 1.5em;
}

/* handle Firefox (arrows always shown) */
@supports (-moz-appearance:none) {
  .cea-input div::after {
    right: 1.5em;
  }
}

/* set the unit abbreviation for each unit class */
.cea-input .percent::after {
  content: '%';
}

.cea-inputReadOnly .ms-TextField-fieldGroup {
  background-color: #f5e8ff !important;
}

.w-fill {
  width: -webkit-fill-available;
}

.h-24 {
  height: 24px;
}

.cea-labelPrimary {
  color: #0f62fe;
}

.cea-colorPrimary {
  color: #0f62fe;
}

.cea-colorPrimary:hover {
  color: #0353e9;
}

.cea-helpBulkButtonIcon {
  color: #BB008A;
  cursor: help !important;
  background-color: transparent;
}

.cea-helpBulkButtonIcon:hover {
  color: #BB008A;
  cursor: help !important;
  background-color: transparent;
}

.cea-helpApiButtonIcon {
  color: #0353e9;
  cursor: help !important;
  background-color: transparent;
}

.cea-helpApiButtonIcon:hover {
  color: #0353e9;
  cursor: help !important;
  background-color: transparent;
}

.cea-colorMono {
  color: #0353e9;
}

.cea-colorMono:hover {
  color: #12061f;
}


.cea-colorWarning {
  color: #BB008A;
}

.cea-colorWarning:hover {
  color: #BB008A;
}

.cea-colorError {
  color: #c4213f;
}

.cea-colorError:hover {
  color: #741924;
}

.cea-filterPanelTitle {
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
}

.cea-sidePanel {}

.cea-sidePanel .ms-Panel-contentInner {
  background-color: #FAF9F8 !important;
}

.cea-commandbar {
  padding: 0px !important;
  border-bottom: 1px solid rgb(190, 187, 184) !important;
  background-color: #FAF9F8;
}

.cea-commandbar .ms-CommandBar {
  height: 30px;
  font-size: 12px;
  background-color: #FAF9F8;
  padding: 0px !important;
}

.cea-commandbar .ms-CommandBar .ms-Button {
  background-color: #FAF9F8;
  font-size: 0.9em;
}

.cea-commandbar .ms-CommandBar .ms-Button:hover {
  background-color: #e5e5e5;
}

.cea-commandbar .ms-CommandBar .ms-Button .ms-Icon {
  font-size: 1.2em;
}

/****/

.cea-commandbar-contrast {
  padding: 0px !important;
  border-bottom: 1px solid rgb(190, 187, 184) !important;
  background-color: #F3F2F1;
}

.cea-commandbar-contrast .ms-CommandBar {
  height: 30px;
  font-size: 12px;
  background-color: #F3F2F1;
  padding: 0px !important;
}

.cea-commandbar-contrast .ms-CommandBar .ms-Button {
  background-color: #F3F2F1;
  font-size: 0.9em;
}

.cea-commandbar-contrast .ms-CommandBar .ms-Button:hover {
  background-color: #F3F2F1;
}

.cea-commandbar-contrast .ms-CommandBar .ms-Button .ms-Icon {
  font-size: 1.2em;
}

.cea-queueFiles {
  cursor: pointer;
  border: 1px solid rgb(191 191 191);
  border-radius: 5px;
  background-color: #e5e5e5;
  padding: 5px;
  margin-right: 5px;
}

.cea-queueFiles:hover {
  box-shadow:
    rgb(92 92 92 / 40%) 4px 4px 16px 1px, rgb(15 98 254 / 11%) 0px 0.6px 1.8px 0px;
}

.cea-label-bold-12 {
  font-weight: 600;
  font-size: 12px;
}

.cea-label-bold-12 {
  font-weight: 600;
  font-size: 12px;
}

.cea-label-italic-12 {
  font-style: italic;
  font-size: 13px;
}

.cea-label-bold-15 {
  font-weight: 600;
  font-size: 15px;
}

.cea-label-fluent {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
    box-sizing: border-box;
    box-shadow: none;
    margin: 0px;
    display: block;
    padding: 5px 0px;
    overflow-wrap: break-word;
}

.cea-page-title {
  font-weight: 600;
  font-size: 18px;
}

.cea-page-subtitle {
  font-weight: 400;
  font-size: 12px;
}

.cea-gridTableResume {
  background-color: #ffffff;
  padding-right: 0px;
  border: 1px #bdc3c7 solid;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.cea-buttonMenuHeader {
  cursor: pointer;
}

.btnHeaderShortcut-uat {
  border: 1px solid #160c0c;
  background-color: #160c0c;
  color: #fff;
}

.btnHeaderShortcut-uat:hover {
  border: 1px solid #380a0a;
  border-radius: 5px;
  background-color: #380a0a;
  color: #ffffff !important;
}

.btnHeaderShortcut-dev {
  border: 1px solid #1e1c11;
  background-color: #1e1c11;
  color: #fff;
}

.btnHeaderShortcut-dev:hover {
  border: 1px solid #38330a;
  border-radius: 5px;
  background-color: #38330a;
  color: #ffffff !important;
}

.btnHeaderShortcut {
  border: 1px solid rgb(24 24 27);
  background-color: rgb(24 24 27);
  color: #fff;
}

.btnHeaderShortcut:hover {
  border: 1px solid #343437;
  border-radius: 5px;
  background-color: #343437;
  color: #ffffff !important;
}

.cea-sidebarNav {
  padding: 0px 12px 0px 12px;
  cursor: pointer;
}

.cea-sidebarItem-uat {
  border: 1px solid #160c0c;
  padding: 10px 16px;
  border-radius: 5px;
  background-color: #160c0c;
  cursor: pointer;
}

.cea-sidebarItem-uat:hover {
  border: 1px solid #380a0a;
  padding: 10px 16px;
  border-radius: 5px;
  background-color: #380a0a;
  color: #ffffff !important;
}

.cea-sidebarItem-dev {
  border: 1px solid #1e1c11;
  padding: 10px 16px;
  border-radius: 5px;
  background-color: #1e1c11;
  cursor: pointer;
}

.cea-sidebarItem-dev:hover {
  border: 1px solid #38330a;
  padding: 10px 16px;
  border-radius: 5px;
  background-color: #38330a;
  color: #ffffff !important;
}

.cea-sidebarItem-uat:hover .cea-sidebarButton {
  color: #ffffff !important;
}

.cea-sidebarItem-uat:hover .ms-Button-flexContainer .ms-Button-icon {
  color: #ffffff !important;
}

.cea-sidebarItem-uat:hover .ms-Button-icon {
  color: #ffffff !important;
}

.cea-sidebarItem-dev:hover .cea-sidebarButton {
  color: #ffffff !important;
}

.cea-sidebarItem-dev:hover .ms-Button-flexContainer .ms-Button-icon {
  color: #ffffff !important;
}

.cea-sidebarItem-dev:hover .ms-Button-icon {
  color: #ffffff !important;
}

.cea-sidebarItem {
  border: 1px solid rgb(24 24 27);
  padding: 10px 16px;
  border-radius: 5px;
  background-color: rgb(24 24 27);
  cursor: pointer;
}

.cea-sidebarItem:hover {
  border: 1px solid #343437;
  padding: 10px 16px;
  border-radius: 5px;
  background-color: #343437;
  color: #ffffff !important;
}

.cea-sidebarItem:hover .cea-sidebarButton {
  color: #ffffff !important;
}

.cea-sidebarItem:hover .ms-Button-flexContainer .ms-Button-icon {
  color: #ffffff !important;
}

.cea-sidebarItem:hover .ms-Button-icon {
  color: #ffffff !important;
}

.cea-sidebarButton {
  color: #a3a3a4;
  width: 100%;
}

/* 
.cea-sidebarButton:hover {
  color: #ffffff !important;
  width: 100%;
} */

.cea-sidebarButton .ms-Button-flexContainer {
  display: grid !important;
  justify-content: center !important;
}


.cea-sidebarButton .ms-Button-flexContainer .ms-Button-icon {
  font-size: 20px ! important;
  color: #a3a3a4 !important;
}

/* .cea-sidebarButton .ms-Button-flexContainer .ms-Button-icon:hover {
  color: #fff !important;
} */


.consultaGrid {
  background-color: #ffffff;
  margin-top: 10px;
}

.table-content-center {
  text-align-last: center;
}

.bg-white {
  background-color: #ffffff;
}

.customDialog_Download {}

.customDialog_Download .ms-Dialog-main {
  width: 40% !important;
}

.customDialog_Download_Container {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  text-align-last: center;
}

.customDialog_Download_Header {
  flex: 1 1 auto;
  border-top: 4px solid #0f62fe;
  color: #0f62fe;
  display: flex;
  font-size: 20px;
  align-items: center;
  font-weight: 500;
  padding: 10px;
  padding-left: 22px;

}

.customDialog_Download_Body {
  flex: 4 4 auto;
  padding: 24px 24px 24px 24px;
  overflow-y: hide;
}

.customDialog_Download_Details {}

.iconFilterSmall {
  color: "blue";
  height: 29px !important;
}

.filterTitleRow {
  background-color: #ffffff;
  border-bottom: 1px rgb(200, 200, 200) solid;
}

.iconFilterSmall .ms-Icon {
  font-size: 12px !important;
}

.text-center-m-b-10 {
  text-align: '-webkit-center';
  margin-bottom: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.cea-color-primary {
  color: #0F62FE;
}

.display-inline {
  display: inline;
}

.display-grid {
  display: grid;
}

.font-10 {
  font-size: 10px;
}

.full-width {
  width: 100%;
}

.wFull_35 {
  width: calc(100% - 35px) !important;
}

.w97_5 {
  width: 97.5% !important;
}

.w97 {
  width: 97% !important;
}

.w98 {
  width: 98% !important;
}

.w100 {
  width: 100% !important;
}

.m-t-3 {
  margin-top: 3px !important;
}

.m-t-7 {
  margin-top: 7px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-12 {
  margin-top: 12px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-b-3 {
  margin-bottom: 3px !important;
}

.m-b-7 {
  margin-bottom: 7px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-12 {
  margin-bottom: 12px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.p-t-2 {
  padding-top: 2px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.pd-2 {
  padding: 2px !important;
}

.pd-5 {
  padding: 5px !important;
}

.pd-10 {
  padding: 10px !important;
}

.pd-12 {
  padding: 12px !important;
}

.pd-15 {
  padding: 15px !important;
}

.pd-20 {
  padding: 20px !important;
}

.t-0 {
  top: 0px !important;
}

.h-20vh {
  height: 20vh !important;
}

.h-25vh {
  height: 25vh !important;
}

.h-30vh {
  height: 30vh !important;
}

.h-35vh {
  height: 35vh !important;
}

.h-40vh {
  height: 40vh !important;
}

.h-45vh {
  height: 45vh !important;
}

.h-48vh {
  height: 48vh !important;
}

.h-50vh {
  height: 50vh !important;
}

.h-55vh {
  height: 55vh !important;
}

.h-65vh {
  height: 65vh !important;
}

.h-37 {
  height: 37px !important;
}

.h-40 {
  height: 40px !important;
}

.table-resume.table>thead>tr>th,
.table>tbody>tr>td {
  padding: 2px !important;
  border-top: none;
  border-bottom: none;
}

.table-resume.table>tbody>tr>td {
  padding: 2px !important;
  border-top: none;
  border-bottom: none;
  border-left: inherit;
}

.table-resume.table>:not(:first-child) {
  border-top: 1px #efefef solid;
}

.table-resume.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0px;
  margin-top: 0px !important;
}

.consulta-resume-cell {
  background-color: rgb(15 98 254 / 28%) !important;
}

.consulta-resume-cell-border {
  background-color: rgb(15 98 254 / 28%) !important;
  border-left: 1px #959797 solid !important
}

#root {
  /* your css here! */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* ------- COMMAND BAR PRIMARY -------- */

.commandbar-btn-primary:is(:enabled) {
  background: #0f62fe !important;
  color: #ffffff !important;
}

.commandbar-btn-primary:is(:disabled){
  background: #c6c6c6 !important;
  color: #e0e0e0 !important;
}

.commandbar-btn-primary .is-disabled {
  background: #000 !important;
  color: #ffffff !important;
}


.commandbar-btn-primary:hover {
  color: #ffffff !important;
  background: #0353e9 !important;
}

.commandbar-btn-primary > span {
  margin-right: 10px !important;
}

.commandbar-btn-primary > span > i {
  color: #ffffff !important;
}

.commandbar-btn-primary *:hover {
  background: #0353e9 !important;
  color: #ffffff !important;
}

/* .root-459:hover .ms-Button-menuIcon {
  background: #0353e9 !important;
  color: #ffffff !important;
} */

/* ------- COMMAND BAR SECONDARY -------- */

.commandbar-btn-secondary {
  background: #393939 !important;
  color: #fff !important;
}

.commandbar-btn-secondary:hover {
  background: #4c4c4c !important;
}

.commandbar-btn-secondary > span {
  margin-right: 10px !important;
}

.commandbar-btn-secondary > span > i {
  color: #fff !important;
}

.no-content {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, sans-serif;
  height: calc(100vh - 15rem);
  padding: calc(15vh + 4rem) 10vh;
  background-color: #ffffff;
}

.no-content-title {
  margin-top: 1vh;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 400;
}

.no-content-description {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 400;
}

.no-content-description > span {
  font-style: italic;
}

.no-content-action {
  margin-top: 1vh;
}

.root-ms-TextField {
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  position: relative;
}